import payload_plugin_Qoo6utHCDV from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.34.2/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_86RAN3IBUo from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0_38f217d6774600b51ae43b83fb621c1e/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_fjXfKuEKbV from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0_38f217d6774600b51ae43b83fb621c1e/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Vyd5sYwJ4T from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0_38f217d6774600b51ae43b83fb621c1e/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_5oYhgK7gb5 from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0_38f217d6774600b51ae43b83fb621c1e/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_63S71rW2iw from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0_38f217d6774600b51ae43b83fb621c1e/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_SBxTdTfjbi from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0_38f217d6774600b51ae43b83fb621c1e/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_46D9Ngyh7q from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0_38f217d6774600b51ae43b83fb621c1e/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_dCWrCyHBfe from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.34.2/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/.nuxt/components.plugin.mjs";
import prefetch_client_8HHri38Ods from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watcher@2.5.1_@types+node@22.13.1_db0@0_38f217d6774600b51ae43b83fb621c1e/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import formkitPlugin_pZqjah0RUG from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/.nuxt/formkitPlugin.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/.nuxt/element-plus-injection.plugin.mjs";
import plugin_client_fUdugSo8s1 from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.34.2/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import unocss_MzCDxu9LMj from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/.nuxt/unocss.mjs";
import ssr_plugin_tTH0rp6p2j from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/node_modules/.pnpm/@vueuse+nuxt@12.5.0_magicast@0.3.5_nuxt@3.15.4_@electric-sql+pglite@0.2.16_@parcel+watc_6b88416b73b2fedb03e00c800ad70365/node_modules/@vueuse/nuxt/ssr-plugin.mjs";
export default [
  payload_plugin_Qoo6utHCDV,
  revive_payload_client_86RAN3IBUo,
  unhead_fjXfKuEKbV,
  router_Vyd5sYwJ4T,
  payload_client_5oYhgK7gb5,
  navigation_repaint_client_63S71rW2iw,
  check_outdated_build_client_SBxTdTfjbi,
  chunk_reload_client_46D9Ngyh7q,
  plugin_vue3_dCWrCyHBfe,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8HHri38Ods,
  formkitPlugin_pZqjah0RUG,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_client_fUdugSo8s1,
  unocss_MzCDxu9LMj,
  ssr_plugin_tTH0rp6p2j
]