
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46all_93wNifDAWqZeMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/[...all].vue?macro=true";
import { default as _91id_93XNiAcEZEB6Meta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/customer_form/[id].vue?macro=true";
import { default as indexqlLvNkfmjSMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/customer_form/index.vue?macro=true";
import { default as _91id_93gBNZ4A97jmMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/estimates/[id].vue?macro=true";
import { default as indexmEWPZGXA45Meta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/estimates/index.vue?macro=true";
import { default as indexPT1VgwHAxUMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/index.vue?macro=true";
import { default as _91id_93JnI8H4YzniMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/inspection/[id].vue?macro=true";
import { default as indexVgRf0YtgV8Meta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/inspection/index.vue?macro=true";
import { default as _91id_935QWc3MCTchMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/invoices/[id].vue?macro=true";
import { default as indexfWSm3pxH0NMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/invoices/index.vue?macro=true";
import { default as _91id_93sWnlofQl1XMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new_customer/[id].vue?macro=true";
import { default as indexeQxHKcM7xcMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new_customer/index.vue?macro=true";
import { default as _91id_93ulzXegsH2HMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new/[id].vue?macro=true";
import { default as indexT9qVojaSDzMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new/index.vue?macro=true";
import { default as policyOprb4WqquEMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/policy.vue?macro=true";
import { default as _91id_93U8EqSQ3TgOMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/schedule/[id].vue?macro=true";
import { default as indexZ9du28yncYMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/schedule/index.vue?macro=true";
import { default as termsodKP33kQvTMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/terms.vue?macro=true";
import { default as transfer_45failB6rrFZLytpMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/transfer-fail.vue?macro=true";
import { default as transfer_45success2YHGQQeVW9Meta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/transfer-success.vue?macro=true";
import { default as unsubscribep0eEfZyGkUMeta } from "/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/unsubscribe.vue?macro=true";
export default [
  {
    name: "all",
    path: "/:all(.*)*",
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/[...all].vue")
  },
  {
    name: "customer_form-id",
    path: "/customer_form/:id()",
    meta: _91id_93XNiAcEZEB6Meta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/customer_form/[id].vue")
  },
  {
    name: "customer_form",
    path: "/customer_form",
    meta: indexqlLvNkfmjSMeta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/customer_form/index.vue")
  },
  {
    name: "estimates-id",
    path: "/estimates/:id()",
    meta: _91id_93gBNZ4A97jmMeta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/estimates/[id].vue")
  },
  {
    name: "estimates",
    path: "/estimates",
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/estimates/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexPT1VgwHAxUMeta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/index.vue")
  },
  {
    name: "inspection-id",
    path: "/inspection/:id()",
    meta: _91id_93JnI8H4YzniMeta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/inspection/[id].vue")
  },
  {
    name: "inspection",
    path: "/inspection",
    meta: indexVgRf0YtgV8Meta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/inspection/index.vue")
  },
  {
    name: "invoices-id",
    path: "/invoices/:id()",
    meta: _91id_935QWc3MCTchMeta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/invoices/[id].vue")
  },
  {
    name: "invoices",
    path: "/invoices",
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/invoices/index.vue")
  },
  {
    name: "new_customer-id",
    path: "/new_customer/:id()",
    meta: _91id_93sWnlofQl1XMeta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new_customer/[id].vue")
  },
  {
    name: "new_customer",
    path: "/new_customer",
    meta: indexeQxHKcM7xcMeta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new_customer/index.vue")
  },
  {
    name: "new-id",
    path: "/new/:id()",
    meta: _91id_93ulzXegsH2HMeta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new/[id].vue")
  },
  {
    name: "new",
    path: "/new",
    meta: indexT9qVojaSDzMeta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/new/index.vue")
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/policy.vue")
  },
  {
    name: "schedule-id",
    path: "/schedule/:id()",
    meta: _91id_93U8EqSQ3TgOMeta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/schedule/[id].vue")
  },
  {
    name: "schedule",
    path: "/schedule",
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/schedule/index.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/terms.vue")
  },
  {
    name: "transfer-fail",
    path: "/transfer-fail",
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/transfer-fail.vue")
  },
  {
    name: "transfer-success",
    path: "/transfer-success",
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/transfer-success.vue")
  },
  {
    name: "unsubscribe",
    path: "/unsubscribe",
    meta: unsubscribep0eEfZyGkUMeta || {},
    component: () => import("/Users/szymondziak/StudioProjects/quoteiq-web-nuxt/pages/unsubscribe.vue")
  }
]